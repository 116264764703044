import FlexibleData, { fetchBuilderDevelopmentPaths } from '@lib/cms/flexibleData';
import BuilderDevelopmentTemplate from '@templates/BuilderDevelopment';

export async function getStaticPaths() {
  const pathData = await fetchBuilderDevelopmentPaths('Development');

  const paths = pathData.map((item) => {
    return {
      params: { slug: item.slug }
    };
  });

  return {
    fallback: false,
    paths
  };
}

export async function getStaticProps(context) {
  const preview = context.preview || false;
  const previewEnv = context?.previewData?.environment || null;
  const data = await FlexibleData.fetchBuilderDevelopmentPage(
    'Development',
    context.params.slug,
    preview,
    previewEnv
  );

  return {
    props: { data, preview }
  };
}

const DevelopmentPage = ({ data, preview }) => {
  return <BuilderDevelopmentTemplate data={data} preview={preview} />;
};

export default DevelopmentPage;
